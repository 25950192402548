import React, { useState } from "react";
import "./App.css";

function App() {
  const [name, setName] = useState("");
  const [idnumber, setIdnumber] = useState("");
  const [expdate, setExpdate] = useState("");
  const [borndate, setBorndate] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [borncity, setBorncity] = useState("");
  const [borncountry, setBorncountry] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [scholar, setScholar] = useState("");
  const [riiesit, setRiiesit] = useState("");
  const [ingdate, setIngdate] = useState("");
  const [regdate, setRegdate] = useState("");
  const [rescity, setRescity] = useState("");
  const [resaddress, setResaddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [civilstate, setCivilstate] = useState("");
  const [contact, setContact] = useState("");
  const [family, setFamily] = useState("");
  const [eps, setEps] = useState("");
  const [pension, setPension] = useState("");
  const [size, setSize] = useState("");
  const [carnet, setCarnet] = useState("");
  const [locality, setLocality] = useState("");
  return (
    <div className="App">
      <h1>Formulario terapeutas nuevos</h1>
      <label>
        Nombre completo:{" "}
        <input type="text" onChange={(e) => setName(e.target.value)} />
      </label>
      <label>
        Número de identificación:{" "}
        <input type="number" onChange={(e) => setIdnumber(e.target.value)} />
      </label>
      <label>
        Fecha de expedición:{" "}
        <input type="date" onChange={(e) => setExpdate(e.target.value)} />
      </label>
      <label>
        Fecha de nacimiento:{" "}
        <input type="date" onChange={(e) => setBorndate(e.target.value)} />
      </label>
      <label>
        Genero:{" "}
        <select onChange={(e) => setGender(e.target.value)}>
          <option hidden> Por favor seleccione una opcion</option>
          <option>Masculino</option>
          <option>Femenino</option>
          <option>Otro</option>
        </select>
      </label>
      <label>
        Edad: <input type="number" onChange={(e) => setAge(e.target.value)} />
      </label>
      <label>
        Ciudad de nacimiento:{" "}
        <input type="text" onChange={(e) => setBorncity(e.target.value)} />
      </label>
      <label>
        Pais de nacimiento:{" "}
        <input type="text" onChange={(e) => setBorncountry(e.target.value)} />
      </label>
      <label>
        Especialidad:{" "}
        <select onChange={(e) => setSpeciality(e.target.value)}>
          <option hidden>Por favor seleccione su especialidad</option>
          <option>Fonoaudiología</option>
          <option>Terapia Ocupacional</option>
          <option>Terapia Fisica</option>
          <option>Psicología</option>
        </select>
      </label>
      <label>
        Formación academica/Profesión principal:{" "}
        <input type="text" onChange={(e) => setScholar(e.target.value)} />
      </label>
      <label>
        Situación actual con RIIE:{" "}
        <select onChange={(e) => setRiiesit(e.target.value)}>
          <option hidden>Seleccione una opcion</option>
          <option>Activo</option>
          <option>Inactivo</option>
        </select>
      </label>
      <label>
        Fecha de ingreso:{" "}
        <input type="date" onChange={(e) => setIngdate(e.target.value)} />
      </label>
      <label>
        Fecha de retiro definitivo:{" "}
        <input type="date" onChange={(e) => setRegdate(e.target.value)} />
      </label>
      <label>
        Ciudad de residencia:{" "}
        <input type="text" onChange={(e) => setRescity(e.target.value)} />
      </label>
      <label>
        Dirección completa de residencia:{" "}
        <input type="text" onChange={(e) => setResaddress(e.target.value)} />
      </label>
      <label>
        Correo electronico:{" "}
        <input type="email" onChange={(e) => setEmail(e.target.value)} />
      </label>
      <label>
        Telefono celular:{" "}
        <input type="number" onChange={(e) => setPhone(e.target.value)} />
      </label>
      <label>
        Estado civil:{" "}
        <input type="text" onChange={(e) => setCivilstate(e.target.value)} />
      </label>
      <label>
        Persona de contacto:{" "}
        <input type="text" onChange={(e) => setContact(e.target.value)} />
      </label>
      <label>
        Nucleo familiar:{" "}
        <input type="text" onChange={(e) => setFamily(e.target.value)} />
      </label>
      <label>
        EPS: <input type="text" onChange={(e) => setEps(e.target.value)} />
      </label>
      <label>
        Pension:{" "}
        <input type="text" onChange={(e) => setPension(e.target.value)} />
      </label>
      <label>
        Talla dotación:{" "}
        <input type="text" onChange={(e) => setSize(e.target.value)} />
      </label>
      <label>Carnet: PENDIENTE</label>
      <label>Sello y firma del profesional: PENDIENTE</label>
      <label>
        Localidad de atención:{" "}
        <input type="text" onChange={(e) => setLocality(e.target.value)} />
      </label>
      <label>
        Hoja de vida: <input type="file" />
      </label>
      <label>
        Fotocopia de diploma profesional y/o tecnico: <input type="file" />
      </label>
      <label>
        Fotocopia de acta de grado profesional: <input type="file" />
      </label>
      <label>
        Fotocopia de cedula de ciudadania por ambos lados: <input type="file" />
      </label>
      <label>
        Fotocopia de tarjeta profesional: <input type="file" />
      </label>
      <label>
        Certificación de RETHUS: <input type="file" />
      </label>
      <label>
        Carné de vacunas esquema completo (Hepatitis B y Tétano). En caso de no
        contar con este documento, debe iniciar a hacerlo luego de ingresar a la
        compañía y entregarlo posteriormente: <input type="file" />
      </label>
      <label>
        Certificado de curso de violencia sexual. En caso de no contar con este
        documento, debe iniciar a hacerlo luego de ingresar a la compañía y
        entregarlo posteriormente: <input type="file" />
      </label>
      <label>
        Certificado de antecedentes de la Policía: <input type="file" />
      </label>
      <label>
        Certificado de antecedentes de la Contraloría: <input type="file" />
      </label>
      <label>
        Certificado de antecedentes de la Procuraduría: <input type="file" />
      </label>
      <label>
        Certificado de EPS actual (beneficiario o cotizante):{" "}
        <input type="file" />
      </label>
      <label>
        Certificado de fondo de pensión (si no cuenta con fondo de pensión, no
        subir nada): <input type="file" />
      </label>
      <label>
        Certificado de fondo de cesantías (omitir si es el mismo fondo de
        pensiones): <input type="file" />
      </label>
      <label>
        Diploma de grado de otros estudios (especialización, maestría) si
        aplica: <input type="file" />
      </label>
      <label>
        Acta de grado de otros estudios (especialización, maestría) si aplica:{" "}
        <input type="file" />
      </label>
      <label>
        Certificado o diploma de otros estudios: <input type="file" />
      </label>
      <label>
        Certificación laboral: <input type="file" />
      </label>
    </div>
  );
}

export default App;
